.session_flow-container {
  text-align: center;
  padding-bottom: 24px;
}

.session_flow-container .link {
  fill: none;
  stroke: #000;
  stroke-opacity: 0.8;
}

.session_flow-container .link:hover {
  stroke-opacity: 0.5;
}

.session_flow-container .node {
  cursor: pointer;
}

.session_flow-container .node-text {
  pointer-events: none;
}

.session_flow-container .node-icon {
  pointer-events: none;
}

.session_flow-container .node-percent-text,
.session_flow-container .node-dropoff-text {
  fill: #fff;
  font-size: 0.65rem;
}

.session_flow-container .node-transcript-icon {
  cursor: pointer;
}

.session_flow-container .node-transcript-text {
  cursor: pointer;
  font-size: 0.85rem;
}

.session-flow-tooltip {
  text-align: left;
  border: 0;
  font-size: 0.75rem;
  pointer-events: none;
  border-radius: 2px;
  background-color: #f1f1f1;
  position: absolute;
  z-index: 10000;
  color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.6);
}

.session-flow-tooltip .green {
  font-weight: bold;
  font-size: 1rem;
  color: #37ca88;
}

.session-flow-tooltip .red {
  font-weight: bold;
  font-size: 1rem;
  color: #ff5252;
}
