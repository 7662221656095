$node-header-font-color: #454980 !default;
$node-background-color: #ffffff !default;
//$node-content-background-color: #dddddd !default;
$node-content-background-color: #fff !default;
$node-content-font-color: #454980 !default;
//$node-button-background-color: #3c90cd !default;
$node-button-background-color: #455472 !default;
//$node-default-button-background-color: #047092 !default;
$node-default-button-background-color: #6298c0 !default;
$node-fallback-button-background-color: #d9776a !default;
$node-button-font-color: #ffffff !default;
$node-slot-header-font-color: #454545 !default;
$node-slot-font-color: #43aefc !default;
$node-slot-background-color: #00455a !default;
//$node-text-input-background-color: #0ac44e !default;
$node-text-input-background-color: #3ec08d !default;
$node-text-input-font-color: #ffffff !default;
//$node-equal-case-background-color: #ff8000 !default;
//$node-equal-case-background-color: #e0a46f !default;
$node-equal-case-background-color: #a77982 !default;
$node-equal-case-font-color: #ffffff !default;
//$node-contain-case-background-color: #ff3000 !default;
$node-contain-case-background-color: #d9776a !default;
$node-contain-case-font-color: #ffffff !default;
$node-end-node-background-color: #189497 !default;
$node-end-node-font-color: #ffffff !default;
//$node-suggestion-background-color: #f94569 !default;
//$node-suggestion-background-color: #f94569 !default;
$node-suggestion-background-color: #3ec08d !default;
$node-suggestion-font-color: #ffffff !default;
$node-label-background-color: #ffffff !default;
$node-label-font-color: #454980 !default;
$node-border-color: #d2d2d2 !default;
$node-border: 2px solid $node-border-color !default;
$node-box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  0 2px 4px -2px rgba(0, 0, 0, 0.1);
$node-handle-color: #eaae7b !default;

$choice-background-color: #7bc8ff !default;

//$edge-stroke-color: #ffb03b !default;
$edge-stroke-color: #eaae7b !default;

$condition-node-button-background-color: #dddddd !default;
$condition-node-button-border-color: #7a7578 !default;
$condition-node-button-hover-background-color: rgba(
  100,
  200,
  230,
  0.8
) !default;
$condition-node-button-hover-border-color: #454980 !default;

$edge-button-background-color: #ffeccf !default;
$edge-button-hover-background-color: #ffd393 !default;
//$edge-button-active-background-color: #ffb03b !default;
$edge-button-active-background-color: #f8ba85 !default;

$node-hover-menu-background-color: #ebf1f5 !default;
$node-hover-menu-hover-background-color: #e1eaf0 !default;
$node-hover-menu-active-background-color: #cedae2 !default;
$node-hover-menu-font-color: #7a7578 !default;
