.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-backward {
  float: left;
}
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-forward {
  float: right;
}
.rs-picker-toggle.rs-btn-lg {
  font-size: 13px;
}
.rs-picker-toggle-value {
  margin-right: 30px;
  width: auto;
}
.rs-picker-toggle-clean {
  right: 70px;
  left: initial !important;
}
.rs-picker-toolbar-option {
  font-size: 13px;
  color: #3498ff;
  position: initial !important;
}
.rs-picker-toolbar-ranges {
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: block;
}
.rs-picker-daterange-menu {
  left: initial !important;
  right: 70px;
  margin-top: 2px;
}
.rs-picker-daterange-calendar-group {
  min-width: 480px;
}
.rs-picker-daterange-menu .rs-calendar {
  width: 245px;
}
.rs-calendar-header-error {
  color: #8e8e93 !important;
}
.rs-calendar-header-error:hover {
  background: transparent !important;
}
.rs-picker-toggle.rs-btn.rs-btn-default.rs-btn-md {
  height: 40px;
  line-height: 1.8;
  border-radius: 5px;
}
.rs-calendar {
  width: 280px;
  display: block;
  margin: 0 auto;
}
.rs-picker {
}
.rs-picker-toggle {
  border-radius: 5px;
}
.rs-picker-toggle-value {
  margin-right: 0;
}
