.awaiting_upload_animation {
  animation: rotation 1750ms infinite 500ms
    cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  30% {
    transform: rotate(175deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
