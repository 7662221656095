.react-dropdown-select-dropdown {
  display: flex !important;
  word-break: normal !important;
  width: auto !important;
}

.react-dropdown-select-item {
  color: #000 !important;
}

.react-dropdown-select-item-selected {
  background: none !important;
}

.react-dropdown-select-input {
  color: #3e3f42;
  color: currentColor;
}

.react-dropdown-select-input::placeholder {
  color: #3e3f42;
  color: currentColor;
}

.react-dropdown-select-dropdown-add-new {
  color: #049978 !important;
  border-bottom: 1px solid;
}

.select-topic {
  display: inline-block;
  color: #3e3f42 !important;
  cursor: pointer;
  padding: 5px 5px;
  margin: 5px 0;
  word-break: break-word;
  text-align: center;
  font-size: 0.8rem;
}

.select-topic .react-dropdown-select {
  padding: 5px 8px;
  border-radius: 24px;
  min-height: unset;
}

.select-topic .react-dropdown-select-input {
  font-size: 13px;
}

.select-topic .react-dropdown-select-dropdown-handle {
  height: 16px;
  width: 16px;
  transition: transform 300ms;
}

.select-topic .react-dropdown-select-content {
  display: inline-block;
}
