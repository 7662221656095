.trigger_node_fieldset {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -8px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0;
}

.trigger_node_input_fieldset {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -8px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0;
}
