.public-DraftStyleDefault-block {
  margin: 0.25em 0 !important;
}
.rdw-editor-toolbar {
  border: none !important;
}

.rdw-option-wrapper {
  padding: 2px !important;
  min-width: 20px !important;
}

.rdw-link-modal {
  z-index: 9999 !important;
  height: 240px !important;
}
