.edit_item {
  opacity: 0;
  padding: 0;
  fill: #757575;
  transition: fill 150ms ease-in-out;
}

.bounce_animation {
  opacity: 1;
  animation: bounce-in 300ms ease-in-out forwards;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
