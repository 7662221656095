@import "./dialogue-editor/components/DialogueGraph/dialogue-graph-react-flow";
@import "./dialogue-editor/components/DialogueNodes/ResponseEditor";
@import "./mapping-editor/components/MappingTable/custom_combo_box.css";
@import "../../node_modules/react-sliding-pane/dist/react-sliding-pane.css";
@import "./statistics/components/SessionFlowDiagram/session-flow.css";
@import "../components/header/daterangeselect-rsuite";
@import "./dialogue-editor/components/IntegrationGuide/atom-dark";
@import "./dialogue-editor/components/DialogueGraph/components/QueryBuilder/query-builder";
@import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
@import "../../node_modules/@glideapps/glide-data-grid/dist/index.css";
@import "../../src/styles/document_page.scss";
@import "../../src/styles/upload_table.scss";
@import "../../src/styles/copilot.scss";
@import "../../src/styles/copilot_documents_tree.scss";
@import "../styles/react_resizable";

//Abcfincance CI Fonts
@font-face {
  font-family: "Dax";
  src: url(../../public/assets/fonts/3A8C94_B_0.woff2) format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Dax";
  src: url(../../public/assets/fonts/3A8C94_7_0.woff2) format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Dax";
  src: url(../../public/assets/fonts/3A8C94_8_0.woff2) format("woff2");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

//@import "@nosferatu500/react-sortable-tree/style.css";
//@import '../../node_modules/@remotelock/react-week-scheduler/index.css';
