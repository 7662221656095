:root {
  /* light */
  --copilot-light-color-primary: rgba(0, 81, 158, 1);
  --copilot-light-color-primary-rgb: 0 81 158;
  --copilot-light-color-surface-tint: rgb(143 76 52);
  --copilot-light-color-on-primary: rgba(255, 255, 255, 1);
  --copilot-light-color-primary-container: rgba(214, 227, 255, 1);
  --copilot-light-color-on-primary-container: rgba(0, 27, 61, 1);
  --copilot-light-color-secondary: rgba(0, 50, 92, 1);
  --copilot-light-color-secondary-rgb: 0 50 92;
  --copilot-light-color-on-secondary: rgba(255, 255, 255, 1);
  --copilot-light-color-secondary-container: rgba(211, 228, 255, 1);
  --copilot-light-color-on-secondary-container: rgba(0, 28, 56, 1);
  --copilot-light-color-tertiary: rgba(251, 186, 0, 1);
  --copilot-light-color-on-tertiary: rgba(255, 255, 255, 1);
  --copilot-light-color-tertiary-container: rgba(255, 222, 162, 1);
  --copilot-light-color-on-tertiary-container: rgba(38, 25, 0, 1);
  --copilot-light-color-error: rgba(248, 92, 79, 1);
  --copilot-light-color-on-error: rgba(255, 255, 255, 1);
  --copilot-light-color-error-container: rgba(255, 218, 213, 1);
  --copilot-light-color-on-error-container: rgba(59, 9, 6, 1);
  --copilot-light-color-background: rgba(249, 249, 255, 1);
  --copilot-light-color-on-background: rgba(25, 28, 32, 1);
  --copilot-light-color-surface: rgba(249, 249, 255, 1);
  --copilot-light-color-on-surface: rgba(25, 28, 32, 1);
  --copilot-light-color-on-surface-rgb: 25 28 32;
  --copilot-light-color-surface-variant: rgba(232, 234, 244, 1);
  --copilot-light-color-surface-variant-rgb: 232 234 244;
  --copilot-light-color-on-surface-variant: rgba(67, 71, 78, 1);
  --copilot-light-color-outline: rgba(116, 119, 127, 1);
  --copilot-light-color-outline-variant: rgba(196, 198, 207, 1);
  --copilot-light-color-shadow: rgba(0, 0, 0, 1);
  --copilot-light-color-scrim: rgba(0, 0, 0, 1);
  --copilot-light-color-scrim-rgb: 0 0 0;
  --copilot-light-color-inverse-surface: rgba(46, 48, 53, 1);
  --copilot-light-color-inverse-on-surface: rgba(240, 240, 247, 1);
  --copilot-light-color-inverse-primary: rgba(168, 200, 255, 1);
  --copilot-light-color-primary-fixed: rgba(214, 227, 255, 1);
  --copilot-light-color-on-primary-fixed: rgba(0, 27, 61, 1);
  --copilot-light-color-primary-fixed-dim: rgba(168, 200, 255, 1);
  --copilot-light-color-on-primary-fixed-variant: rgba(37, 71, 119, 1);
  --copilot-light-color-secondary-fixed: rgba(211, 228, 255, 1);
  --copilot-light-color-on-secondary-fixed: rgba(0, 28, 56, 1);
  --copilot-light-color-secondary-fixed-dim: rgba(163, 201, 254, 1);
  --copilot-light-color-on-secondary-fixed-variant: rgba(30, 72, 117, 1);
  --copilot-light-color-tertiary-fixed: rgba(255, 222, 162, 1);
  --copilot-light-color-on-tertiary-fixed: rgba(38, 25, 0, 1);
  --copilot-light-color-tertiary-fixed-dim: rgba(235, 193, 108, 1);
  --copilot-light-color-on-tertiary-fixed-variant: rgba(92, 66, 0, 1);
  --copilot-light-color-surface-dim: rgba(217, 217, 224, 1);
  --copilot-light-color-surface-bright: rgba(249, 249, 255, 1);
  --copilot-light-color-surface-container-lowest: rgba(255, 255, 255, 1);
  --copilot-light-color-surface-container-low: rgba(237, 239, 244, 1);
  --copilot-light-color-surface-container: rgba(255, 255, 255, 1);
  --copilot-light-color-surface-container-high: rgba(245, 247, 249, 1);
  --copilot-light-color-surface-container-highest: rgba(226, 226, 233, 1);
  --copilot-light-elevation-1: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  --copilot-light-elevation-2: 0px 1px 2px 0px rgba(0, 0, 0, 0.3),
    0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  --copilot-light-elevation-3: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  --copilot-light-elevation-4: 0px 6px 10px 4px rgba(0, 0, 0, 0.15),
    0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  --copilot-light-elevation-5: 0px 12px 8px 6px rgba(0, 0, 0, 0.15),
    0px 4px 4px 0px rgba(0, 0, 0, 0.3);
  --copilot-inset-shadow-light: inset 1px 1px 4px 1px hsl(0deg 0% 79.74%),
    inset -1px -1px 4px 1px hsl(0deg 0% 100%);
  --copilot-light-color-chat-message-background: var(
    --copilot-light-color-surface-variant
  );
  --copilot-light-color-chat-message-text: var(
    --copilot-light-color-on-surface-variant
  );
  --copilot-light-color-chat-bot-message-text: var(
    --copilot-light-color-surface-variant-rgb
  );
  --copilot-light-color-chat-bot-message-background: rgb(
    var(--copilot-light-color-surface-variant-rgb) / 0.4
  );

  /* dark */
  --copilot-dark-color-primary: rgb(220 184 255);
  --copilot-dark-color-primary-rgb: 220 184 255;
  --copilot-dark-color-surface-tint: rgb(220 184 255);
  --copilot-dark-color-on-primary: rgb(68 23 111);
  --copilot-dark-color-primary-container: rgb(107 65 151);
  --copilot-dark-color-on-primary-container: rgb(255 247 255);
  --copilot-dark-color-secondary: rgb(208 193 218);
  --copilot-dark-color-secondary-rgb: 208 193 218;
  --copilot-dark-color-on-secondary: rgb(54 44 63);
  --copilot-dark-color-secondary-container: rgb(124 112 134);
  --copilot-dark-color-on-secondary-container: rgb(255 255 255);
  --copilot-dark-color-tertiary: rgb(255 195 176);
  --copilot-dark-color-on-tertiary: rgb(92 25 0);
  --copilot-dark-color-tertiary-container: rgb(247 138 100);
  --copilot-dark-color-on-tertiary-container: rgb(59 13 0);
  --copilot-dark-color-error: rgb(255 180 171);
  --copilot-dark-color-on-error: rgb(105 0 5);
  --copilot-dark-color-error-container: rgb(204 71 61);
  --copilot-dark-color-error-container-rgb: 204 71 61;
  --copilot-dark-color-on-error-container: rgb(255 255 255);
  --copilot-dark-color-background: rgb(21 18 24);
  --copilot-dark-color-on-background: rgb(232 224 232);
  --copilot-dark-color-surface: rgb(21 18 24);
  --copilot-dark-color-on-surface: rgb(232 224 232);
  --copilot-dark-color-on-surface-rgb: 232 224 232;
  --copilot-dark-color-surface-variant: rgb(75 68 80);
  --copilot-dark-color-surface-variant-rgb: 75 68 80;
  --copilot-dark-color-on-surface-variant: rgb(205 195 210);
  --copilot-dark-color-outline: rgb(151 142 155);
  --copilot-dark-color-outline-variant: rgb(75 68 80);
  --copilot-dark-color-shadow: rgb(0 0 0);
  --copilot-dark-color-scrim: rgb(0 0 0);
  --copilot-dark-color-scrim-rgb: 0 0 0;
  --copilot-dark-color-inverse-surface: rgb(232 224 232);
  --copilot-dark-color-inverse-on-surface: rgb(51 47 53);
  --copilot-dark-color-inverse-primary: rgb(117 74 161);
  --copilot-dark-color-primary-fixed: rgb(240 219 255);
  --copilot-dark-color-on-primary-fixed: rgb(44 0 81);
  --copilot-dark-color-primary-fixed-dim: rgb(220 184 255);
  --copilot-dark-color-on-primary-fixed-variant: rgb(92 49 135);
  --copilot-dark-color-secondary-fixed: rgb(236 221 246);
  --copilot-dark-color-on-secondary-fixed: rgb(33 24 42);
  --copilot-dark-color-secondary-fixed-dim: rgb(208 193 218);
  --copilot-dark-color-secondary-fixed-dim-rgb: 208 193 218;
  --copilot-dark-color-on-secondary-fixed-variant: rgb(77 67 87);
  --copilot-dark-color-tertiary-fixed: rgb(255 219 207);
  --copilot-dark-color-on-tertiary-fixed: rgb(57 12 0);
  --copilot-dark-color-tertiary-fixed-dim: rgb(255 181 156);
  --copilot-dark-color-on-tertiary-fixed-variant: rgb(125 45 13);
  --copilot-dark-color-surface-dim: rgb(21 18 24);
  --copilot-dark-color-surface-bright: rgb(60 56 62);
  --copilot-dark-color-surface-container-lowest: rgb(16 13 18);
  --copilot-dark-color-surface-container-low: rgb(21 18 24);
  --copilot-dark-color-surface-container: rgb(34 30 36);
  --copilot-dark-color-surface-container-high: rgb(44 41 47);
  --copilot-dark-color-surface-container-highest: rgb(55 51 58);
  --copilot-dark-elevation-1: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  --copilot-dark-elevation-2: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  --copilot-dark-elevation-3: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
    0px 1px 3px 0px rgba(0, 0, 0, 0.3);
  --copilot-dark-elevation-4: 0px 6px 10px 4px rgba(0, 0, 0, 0.15),
    0px 2px 3px 0px rgba(0, 0, 0, 0.3);
  --copilot-dark-elevation-5: 0px 12px 8px 6px rgba(0, 0, 0, 0.15),
    0px 4px 4px 0px rgba(0, 0, 0, 0.3);
  --copilot-inset-shadow-dark: inset 1px 1px 4px 1px hsl(0deg 0% 0%),
    inset -1px -1px 4px 1px hsl(280deg 8.55% 51.18%);
  --copilot-dark-color-chat-message-background: var(
    --copilot-dark-color-surface-variant
  );
  --copilot-dark-color-chat-message-text: var(
    --copilot-dark-color-on-surface-variant
  );
  --copilot-dark-color-chat-bot-message-text: var(
    --copilot-dark-color-on-surface-variant
  );
  --copilot-dark-color-chat-bot-message-background: rgb(
    var(--copilot-dark-color-surface-variant-rgb) / 0.4
  );
  --ease-3: cubic-bezier(0.25, 0, 0.3, 1);
  --ease-in-out-5: cubic-bezier(0.9, 0, 0.1, 1);
  --ease-elastic-3: cubic-bezier(0.5, -0.5, 0.1, 1.5);
  --ease-elastic-4: cubic-bezier(0.5, -0.7, 0.1, 1.5);
}

.copilot {
  &[data-theme="light"] {
    color-scheme: light;
    --copilot-color-primary: var(--copilot-light-color-primary);
    --copilot-color-on-primary: var(--copilot-light-color-on-primary);
    --copilot-color-primary-inverse: var(--copilot-light-color-inverse-primary);
    --copilot-color-secondary: var(--copilot-light-color-secondary);
    --copilot-color-on-secondary-fixed-variant: var(
      --copilot-light-color-on-secondary-fixed-variant
    );
    --copilot-color-tertiary: var(--copilot-light-color-tertiary);
    --copilot-color-on-tertiary: var(--copilot-light-color-on-tertiary);
    --copilot-background-document-viewer: var(
      --copilot-light-color-surface-container-low
    );
    --copilot-color-on-error: var(--copilot-light-color-on-error);
    --copilot-color-error: var(--copilot-light-color-error);
    --copilot-color-on-error-container: var(
      --copilot-light-color-on-error-container
    );
    --copilot-color-error-container: var(--copilot-light-color-error-container);
    --copilot-background: var(--copilot-light-color-surface-container);
    --copilot-on-background: var(--copilot-light-color-on-surface);
    --copilot-sidebar-background: var(--copilot-background);
    --copilot-on-sidebar-background: var(--copilot-light-color-on-surface);
    --copilot-on-sidebar-background-variant: var(
      --copilot-light-color-on-surface-variant
    );
    --copilot-color-surface-variant: var(--copilot-light-color-surface-variant);
    --copilot-color-on-surface-variant: var(
      --copilot-light-color-on-surface-variant
    );
    --copilot-sidebar-element-hover: var(--copilot-color-surface-variant);
    --copilot-sidebar-element-selected: var(
      --copilot-light-color-secondary-container
    );
    --copilot-on-sidebar-element-selected: var(
      --copilot-light-color-on-secondary-container
    );
    --copilot-border-color: var(--copilot-light-color-outline);
    --copilot-border-color-variant: var(--copilot-light-color-outline-variant);
    --copilot-dialog-background: var(
      --copilot-light-color-surface-container-low
    );
    --copilot-dialog-surface: var(
      --copilot-light-color-surface-container-highest
    );
    --copilot-on-dialog-background: var(--copilot-light-color-on-surface);
    --copilot-dialog-scrim: rgb(var(--copilot-light-color-scrim-rgb) / 0.4);
    --copilot-dialog-input-placeholder: rgb(
      var(--copilot-light-color-on-surface-rgb) / 0.6
    );
    --copilot-toggle-svg-color: rgb(
      var(--copilot-light-color-on-surface-rgb) / 0.4
    );
    --copilot-dialog-input-color: rgb(
      var(--copilot-light-color-on-surface-rgb) / 0.87
    );
    --copilot-popupmenu-background: var(--copilot-dialog-surface);
    --copilot-popupmenu-item-hover: var(
      --copilot-light-color-on-secondary-fixed-variant
    );
    --copilot-button-transition: "background 150ms ease-out, color 150ms ease-out";
    --copilot-button-color: var(--copilot-light-color-primary);
    --copilot-button-text-color: var(--copilot-light-color-on-primary);
    --copilot-button-hover-color: var(--copilot-light-color-inverse-primary);
    --copilot-on-button-hover-color: var(
      --copilot-light-color-on-primary-container
    );
    --copilot-switch-track-color: var(--copilot-color-surface-variant);
    --copilot-switch-base-color: var(--copilot-light-color-on-surface-variant);
    --copilot-checkbox-selected-color: var(--copilot-color-primary);
    --copilot-checkbox-hover-color: var(--copilot-color-primary);
    --copilot-icon-button-color: var(--copilot-on-sidebar-background);
    --copilot-icon-button-hover-color: var(
      --copilot-on-sidebar-element-selected
    );
    --copilot-favorite-icon-button-hover-color: var(
      --copilot-light-color-tertiary
    );
    --copilot-trash-icon-button-hover-color: var(
      --copilot-light-color-error-container
    );
    --copilot-background-where-checkbox-selected: var(
      --copilot-color-surface-variant
    );

    --copilot-search-collapse-background: var(
      --copilot-light-color-secondary-container
    );
    --copilot-search-collapse-on-background: var(
      --copilot-light-color-on-secondary-container
    );

    --copilot-color-configuration-drag-handle-hover: rgb(
      var(--copilot-light-color-secondary-rgb) / 0.4
    );
    --copilot-elevation-1: var(--copilot-light-elevation-1);
    --copilot-elevation-2: var(--copilot-light-elevation-2);
    --copilot-elevation-3: var(--copilot-light-elevation-3);
    --copilot-elevation-4: var(--copilot-light-elevation-4);
    --copilot-elevation-5: var(--copilot-light-elevation-5);
    --copilot-color-surface-container-low: var(
      --copilot-light-color-surface-container-low
    );
    //chat
    --copilot-color-icon: var(--copilot-border-color);
    --copilot-chat-background: var(
      --copilot-light-color-surface-container-high
    );
    --copilot-color-surface-container-high: var(
      --copilot-light-color-surface-container-high
    );
    --copilot-color-chat-message-background: var(
      --copilot-light-color-chat-message-background
    );
    --copilot-color-chat-message-text: var(
      --copilot-light-color-chat-message-text
    );
    --copilot-color-chat-bot-message-text: var(
      --copilot-light-color-chat-bot-message-text
    );
    --copilot-color-chat-bot-message-background: var(
      --copilot-light-color-chat-bot-message-background
    );
    --copilot-button-more-background-color: var(
      --copilot-color-surface-variant
    );
    --copilot-color-on-surface: var(--copilot-light-color-on-surface);
    --copilot-color-primary-fixed-dim: var(
      --copilot-light-color-primary-fixed-dim
    );
    --copilot-color-surface-container: var(--copilot-chat-background);
    --copilot-color-surface-container-highest: var(
      --copilot-light-color-surface-container-highest
    );

    --copilot-inset-shadow: var(--copilot-inset-shadow-light);
    --copilot-document-viewer-background: var(
      --copilot-light-color-surface-dim
    );
  }

  &[data-theme="dark"] {
    color-scheme: dark;
    --copilot-color-primary: var(--copilot-dark-color-primary);
    --copilot-color-on-primary: var(--copilot-dark-color-on-primary);
    --copilot-color-primary-inverse: var(--copilot-dark-color-inverse-primary);
    --copilot-color-secondary: var(--copilot-dark-color-secondary);
    --copilot-color-on-secondary-fixed-variant: var(
      --copilot-dark-color-on-secondary-fixed-variant
    );
    --copilot-color-tertiary: var(--copilot-dark-color-tertiary);
    --copilot-color-on-tertiary: var(--copilot-dark-color-on-tertiary);
    --copilot-color-on-error: var(--copilot-dark-color-on-error);
    --copilot-color-error: var(--copilot-dark-color-error);
    --copilot-color-on-error-container: var(
      --copilot-dark-color-on-error-container
    );
    --copilot-color-error-container: var(--copilot-dark-color-error-container);
    --copilot-background-document-viewer: var(
      --copilot-dark-color-surface-container-low
    );
    --copilot-background: var(--copilot-dark-color-surface-container);
    --copilot-on-background: var(--copilot-dark-color-on-surface);
    --copilot-sidebar-background: var(
      --copilot-dark-color-surface-container-high
    );
    --copilot-on-sidebar-background: var(--copilot-dark-color-on-surface);
    --copilot-color-surface-variant: var(--copilot-dark-color-surface-variant);
    --copilot-color-on-surface-variant: var(
      --copilot-dark-color-on-surface-variant
    );
    --copilot-on-sidebar-background-variant: var(
      --copilot-dark-color-on-surface-variant
    );

    --copilot-sidebar-element-hover: var(--copilot-color-surface-variant);
    --copilot-sidebar-element-selected: var(
      --copilot-dark-color-secondary-container
    );
    --copilot-on-sidebar-element-selected: var(
      --copilot-dark-color-on-secondary-container
    );
    --copilot-border-color: var(--copilot-dark-color-outline);
    --copilot-border-color-variant: var(--copilot-dark-color-outline-variant);
    --copilot-dialog-background: var(
      --copilot-dark-color-surface-container-high
    );
    --copilot-dialog-surface: var(
      --copilot-dark-color-surface-container-highest
    );
    --copilot-on-dialog-background: var(--copilot-dark-color-on-surface);
    --copilot-dialog-scrim: rgb(var(--copilot-dark-color-scrim-rgb) / 0.4);
    --copilot-dialog-input-placeholder: rgb(
      var(--copilot-dark-color-on-surface-rgb) / 0.6
    );
    --copilot-toggle-svg-color: rgb(
      var(--copilot-dark-color-on-surface-rgb) / 0.4
    );
    --copilot-dialog-input-color: rgb(
      var(--copilot-dark-color-on-surface-rgb) / 0.87
    );
    --copilot-popupmenu-background: var(--copilot-dialog-surface);
    --copilot-popupmenu-item-hover: var(
      --copilot-dark-color-on-secondary-fixed-variant
    );
    --copilot-button-transition: "background 150ms ease-out, color 150ms ease-out";
    --copilot-button-color: var(--copilot-dark-color-primary);
    --copilot-button-text-color: var(--copilot-dark-color-on-primary);
    --copilot-button-hover-color: var(--copilot-dark-color-inverse-primary);
    --copilot-on-button-hover-color: var(
      --copilot-dark-color-on-primary-container
    );
    --copilot-switch-track-color: var(--copilot-color-surface-variant);
    --copilot-switch-base-color: var(--copilot-dark-color-on-surface-variant);
    --copilot-checkbox-selected-color: var(--copilot-color-primary);
    --copilot-checkbox-hover-color: var(--copilot-color-primary);
    --copilot-icon-button-color: var(--copilot-on-sidebar-background);
    --copilot-icon-button-hover-color: var(
      --copilot-on-sidebar-element-selected
    );
    --copilot-favorite-icon-button-hover-color: var(
      --copilot-dark-color-tertiary
    );
    --copilot-trash-icon-button-hover-color: var(
      --copilot-dark-color-error-container
    );
    --copilot-background-where-checkbox-selected: var(
      --copilot-color-surface-variant
    );

    --copilot-search-collapse-background: var(
      --copilot-dark-color-secondary-container
    );
    --copilot-search-collapse-on-background: var(
      --copilot-dark-color-on-secondary-container
    );

    --copilot-color-configuration-drag-handle-hover: rgb(
      var(--copilot-dark-color-secondary-rgb) / 0.4
    );
    --copilot-elevation-1: var(--copilot-dark-elevation-1);
    --copilot-elevation-2: var(--copilot-dark-elevation-2);
    --copilot-elevation-3: var(--copilot-dark-elevation-3);
    --copilot-elevation-4: var(--copilot-dark-elevation-4);
    --copilot-elevation-5: var(--copilot-dark-elevation-5);

    //chat
    --copilot-color-icon: var(--copilot-border-color);
    --copilot-chat-background: var(--copilot-background);
    --copilot-color-chat-message-background: var(
      --copilot-dark-color-chat-message-background
    );
    --copilot-color-chat-message-text: var(
      --copilot-dark-color-chat-message-text
    );
    --copilot-color-chat-bot-message-text: var(
      --copilot-dark-color-chat-bot-message-text
    );
    --copilot-color-chat-bot-message-background: var(
      --copilot-dark-color-chat-bot-message-background
    );

    --copilot-button-more-background-color: var(
      --copilot-dark-color-surface-container-high
    );
    --copilot-color-surface-container-high: var(
      --copilot-dark-color-surface-container-high
    );
    --copilot-color-on-surface: var(--copilot-dark-color-on-surface);
    --copilot-color-primary-fixed-dim: var(
      --copilot-dark-color-primary-fixed-dim
    );
    --copilot-color-surface-container: var(
      --copilot-dark-color-surface-container
    );
    --copilot-color-surface-container-highest: var(
      --copilot-dark-color-surface-container-highest
    );
    --sidebar-on-element-selected: var(
      --copilot-dark-color-on-secondary-container
    );
    --copilot-inset-shadow: var(--copilot-inset-shadow-dark);
    --copilot-document-viewer-background: var(--copilot-dark-color-surface-dim);
  }

  width: 100%;
  overflow: hidden;
  color: var(--copilot-on-background);
  background: var(--copilot-background);

  .sidebar {
    background: var(--copilot-sidebar-background);
    color: var(--copilot-on-sidebar-background);

    &::-webkit-scrollbar-thumb,
    & *::-webkit-scrollbar-thumb {
      background-color: var(--copilot-sidebar-element-selected);
    }

    &::-webkit-scrollbar-corner,
    & *::-webkit-scrollbar-corner {
      background-color: var(--copilot-sidebar-element-selected);
    }

    &::-webkit-scrollbar-track,
    & *::-webkit-scrollbar-track {
      background: transparent;
      border: 0px none #ffffff;
      border-radius: 12px;
    }
  }

  .document_tool {
    &::-webkit-scrollbar-thumb,
    & *::-webkit-scrollbar-thumb {
      background-color: var(--copilot-sidebar-element-selected);
    }

    &::-webkit-scrollbar-track,
    & *::-webkit-scrollbar-track {
      background: transparent;
      border: 0px none #ffffff;
      border-radius: 12px;
    }
  }

  #pages {
    &::-webkit-scrollbar-thumb,
    & *::-webkit-scrollbar-thumb {
      background-color: var(--copilot-sidebar-element-selected);
    }

    &::-webkit-scrollbar-track,
    & *::-webkit-scrollbar-track {
      background: transparent;
      border: 0px none #ffffff;
      border-radius: 12px;
    }

    &::-webkit-scrollbar-corner,
    & *::-webkit-scrollbar-corner {
      background-color: var(--copilot-background-document-viewer);
    }
  }

  .document-resizing {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: transparent;
      z-index: 100;
      opacity: 0.5;
    }
  }

  .resizable-child-container {
    height: 100%;
    overflow: hidden;
  }

  .sidebar_toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    transition: background-color 0.2s ease-out, cursor 0.2s ease-out;
    position: relative;
    flex-shrink: 0;

    & > button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px;
      transition: background-color 0.2s ease-out, cursor 0.2s ease-out;

      & > svg {
        fill: var(--copilot-toggle-svg-color);
        width: 20px;
        height: 20px;
        transition: fill 0.2s ease-out, transform 200ms ease-out;

        &:hover {
          fill: var(--copilot-on-background);
        }
      }

      &:hover {
        cursor: pointer;
        background-color: transparent;

        & > svg {
          fill: var(--copilot-on-background);
        }
      }
    }
  }

  .popup {
    background-color: var(--popup);
  }

  .highlight {
    background-color: var(--highlight);
  }

  .text-primary {
    color: var(--copilot-on-background);
  }

  .text-secondary {
    color: var(--text2);
  }

  //HALASOASO
  .sun-and-moon > :is(.moon, .sun, .sun-beams) {
    transform-origin: center center;
  }

  .sun-and-moon > :is(.moon, .sun) {
    fill: var(--icon-fill);
  }

  .theme-toggle:is(:hover, :focus-visible) > .sun-and-moon > :is(.moon, .sun) {
    fill: var(--icon-fill-hover);
  }

  .sun-and-moon > .sun-beams {
    stroke: var(--icon-fill);
    stroke-width: 2px;
  }

  .theme-toggle:is(:hover, :focus-visible) .sun-and-moon > .sun-beams {
    stroke: var(--icon-fill-hover);
  }

  &[data-theme="dark"] .sun-and-moon > .sun {
    transform: scale(1.75);
  }

  &[data-theme="dark"] .sun-and-moon > .sun-beams {
    opacity: 0;
  }

  &[data-theme="dark"] .sun-and-moon > .moon > circle {
    transform: translate(-7px);
  }

  @supports (cx: 1) {
    &[data-theme="dark"] .sun-and-moon > .moon > circle {
      transform: translate(0);
      cx: 17;
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    .sun-and-moon > .sun {
      transition: transform 0.5s var(--ease-elastic-3);
    }
    .sun-and-moon > .sun-beams {
      transition: transform 0.5s var(--ease-elastic-4),
        opacity 0.5s var(--ease-3);
    }
    .sun-and-moon .moon > circle {
      transition: transform 0.25s var(--ease-out-5);
    }
    @supports (cx: 1) {
      .sun-and-moon .moon > circle {
        transition: cx 0.25s var(--ease-out-5);
      }
    }
    &[data-theme="dark"] .sun-and-moon > .sun {
      transform: scale(1.75);
      transition-timing-function: var(--ease-3);
      transition-duration: 0.25s;
    }
    &[data-theme="dark"] .sun-and-moon > .sun-beams {
      transform: rotate(-25deg);
      transition-duration: 0.15s;
    }
    &[data-theme="dark"] .sun-and-moon > .moon > circle {
      transition-delay: 0.25s;
      transition-duration: 0.5s;
    }
  }

  .theme-toggle {
    //--size: 2rem;
    --icon-fill: hsl(210 10% 30%);
    --icon-fill-hover: hsl(210 10% 15%);
    background: none;
    border: none;
    padding: 0;
    //inline-size: var(--size);
    //block-size: var(--size);
    aspect-ratio: 1;
    border-radius: 50%;
    cursor: pointer;
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
    outline-offset: 5px;
  }

  .theme-toggle > svg {
    //inline-size: 100%;
    //block-size: 100%;
    stroke-linecap: round;
  }

  &[data-theme="dark"] .theme-toggle {
    --icon-fill: hsl(210 10% 70%);
    --icon-fill-hover: hsl(210 15% 90%);
  }

  //.documents-tree {
  //  background-color: red;
  //
  //  .rst__row {
  //    .rst__moveHandle {
  //      background-color: var(--copilot-sidebar-element-selected);
  //    }
  //
  //    .rst__rowContents {
  //      background-color: var(--copilot-sidebar-element-selected);
  //    }
  //  }
  //}

  @media (hover: none) {
    .theme-toggle {
      --size: 48px;
    }
  }

  .grow-animation {
    width: 0;
    animation: __grow_width 0.5s ease-in-out forwards;
  }

  .shrink-animation {
    width: 100%;
    animation: __shrink_width 0.5s ease-in-out forwards;

    * {
      opacity: 0;
    }
  }

  @keyframes pulseBorder {
    0% {
      border-color: var(--copilot-color-primary);
    }
    65% {
      border-color: var(--copilot-color-primary);
    }
    85% {
      border-color: #fff;
    }
    100% {
      border-color: var(--copilot-color-primary);
    }
  }
  @keyframes wobble {
    0%,
    100% {
      transform: scale(1.05) rotate(0deg);
    }
    25% {
      transform: scale(1.05) rotate(1deg);
    }
    75% {
      transform: scale(1.05) rotate(-1deg);
    }
  }
  @keyframes __grow_width {
    to {
      width: 100%;
    }
  }
  @keyframes __shrink_width {
    to {
      width: 0;
    }
  }
}

.copilot-chatIframe {
  transition: opacity 0.25s ease-out;
  opacity: 0; /* Initial state */
}

.list-element {
  width: 98%;
}
